@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');
@import-normalize;

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  background-color: #1a1a1a;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}